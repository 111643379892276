body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus, select:focus {
    outline: none;
}

.center {
	display: flex;
	align-items: center;
	justify-content: center;
}

a:link, a:visited {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.clickable {
	cursor: pointer;
	user-select: none;
}
